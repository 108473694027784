body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.medium{
  background-color: rgb(0, 0, 255);
  height: 100px;
}

.small{
  background-color: rgb(0, 128, 0);
  height: 100px;
}

.header{
  background-color: #114438;
  height: 67px;
  display: flex;
  flex-direction: row;
  align-items: center
}

.drawer_button{
  cursor: pointer;
  height: 50px;
  margin-left: 10px;
}

.title{
  margin-left: auto;
  margin-right: 30px;
  color: rgb(255, 255, 255);
}

.large_home_body{
  margin: 5% 10%;
  flex-direction: row;
  display: flex;
  flex: 1;
}
.default_font{
  font-weight: normal;
  font-size: 20px;
  white-space: pre-wrap;
}

.agreement_font{
  font-weight: normal;
  font-size: 14px;
  white-space: pre-wrap;
  line-height: 1.2rem;
}

.hyperlink{
  text-decoration: underline;
  font-weight: normal;
  font-size: 20px;
  color: rgb(0, 100, 0);
  cursor: pointer;
}

.image_and_buttons{
  margin-left: 10%;
}

.textbox{
  max-width: 20%;
}

.contract_image{
  width: 300px;
  height: 300px;
}

.heading_text{
  font-size: 34px;
  font-weight: bold;
}

.footer{
  background-color: rgb(128, 128, 128);
  border-top: 1px solid rgb(231, 231, 231);
  left: 0;
  bottom: 0;
  height: 60px;
  width: 100%;
}

.footer_text{
  font-size: 16px;
  color: rgb(255, 255, 255);
  margin-left: 5%;
  font-weight: normal;
}

.nav_home_button{
  width: 200px;
  height: 50px;
  background-color: rgb(17, 68, 56);
  align-items: center;
  display: flex;
  justify-content: center;
  justify-items: center;
  margin: 20px;
  cursor: pointer;
  border-radius: 25px;
}

.nav_home_button:hover{
  background-color: rgb(47, 98, 86);
}

.column{
  flex-direction: column;
  display: flex;
  align-items: center;
}

.button_text{
  color: rgb(255, 255, 255);
  font-size: 16px;
  text-align: center;
  vertical-align: middle;
}

.form_box{
  background-color: rgb(238, 238, 238);
  height: 80%;
  width: 100%;
  margin: 1rem 10rem;
  padding: 20px;
  box-shadow: 10px 10px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.row{
  flex-direction: row;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}

textarea{
  resize: none;
  border: 1px solid rgb(221, 221, 221);
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  width: 100%;
}

.form-input{
  border: 1px solid rgb(221, 221, 221);
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  height: 1.8rem;
  width: 15rem ;
}

.spacer{
  margin-left: auto;
  margin-right: auto;
}

.right{
  margin-left: auto;
}

.btn {
  display: inline-block;
  background: rgb(73, 166, 233);
  color: rgb(255, 255, 255);
  padding: 0.25rem 0.75rem;
  border-radius: 0.25rem;
  border-color: transparent;
  text-transform: capitalize;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  margin-top: 2rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
  transition: all 0.3s linear;
}


